@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./sass/abstracts/variables";

//You may see some unusual way of handling scss, like for instance if we have a hover component its not declared in its parent element
//thats because of antd's unusual way of handling hovers, so its styled like a regular css component

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-color: #f1f3f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

.officeBranch {
  font-size: 1.75em;
}

.FormLogin {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.acccess-denied-wrap {
  display: flex;
  align-items: center;
  height: 72vh;
  width: 50%;
  text-align: center;

  h1 {
    color: red;
    position: relative;
    left: 50%;
  }
}

.MyAccountForm {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
  margin-top: 15px;
}

.login-form-button:focus {
  outline: none !important;
  border: 3px solid rgb(0, 0, 0);
  box-shadow: 0 0 15px #719ece;
}

.header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 300%;
  color: white;
}

.save-changes {
  width: 100%;
  margin-top: 5px;
}

input {
  width: 100%;
  margin-bottom: 100;
}

.custominput:focus {
  outline: none !important;
  border: 3px solid rgb(0, 0, 0);
  box-shadow: 0 0 15px #719ece;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  max-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}
p.lorem-text {
  text-align: justify;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

#addButton {
  margin-top: 1%;
  margin-right: 1%;
  display: inline-block;
  width: 133px;
  margin-right: 23px;
}

.formButton {
  margin-right: 30px;
  display: inline-block;
}

h1 {
  margin-left: 1%;
  font-size: 3em;
  display: inline-block;
}

.editButton {
  float: right;
  margin-right: 10px;
  background-color: $teal;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 7px;
  &:hover {
    background-color: $tealHover;
  }
  &::after {
    background-color: $teal;
  }
  &:focus {
    background-color: $teal;
    &:hover {
      background-color: $tealHover;
    }
  }
}

.deleteButton {
  color: white;
  float: right;
  background-color: $red;
  border-radius: 17px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $redHover;
  }
}

.uploadOfficePlan {
  color: white;
}

.list {
  background-color: white;
}

.conferenceRoomCapacity {
  float: right;
  margin-right: 10px;
  max-width: 50px;
  float: inline-end;
}

.ant-layout,
.container {
  background-image: url("./assets/background.png");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0 -20rem;
  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -o-background-attachment: fixed;
  -ms-backgroudn-attachment: fixed;
  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ant-col {
  padding: 0;
}

td {
  width: 25%;
}

.ant-menu-item-selected {
  color: $teal !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right-color: $teal;
}
.ant-menu-item-selected .ant-menu-title-content a {
  color: $teal;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: $teal;
}

.ant-menu-item-active a:hover {
  color: $teal;
}

.ant-modal-close-x {
  display: none;
}

.addUserModal {
  width: 420px !important;
  .ant-modal-close-x {
    display: block;
  }
}

.ant-pagination-item {
  color: $teal;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.ant-pagination-item:hover,
.ant-pagination-item a:hover {
  color: white;
  background-color: $teal;
  border-radius: 50%;
  border-color: transparent;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: teal;
}

.ant-pagination-item-active,
.ant-pagination-item-active a {
  color: white;
  background-color: $teal;
  border-radius: 50%;
  border-color: transparent;
}

.ant-layout {
  max-height: 100vh;
  background-color: #f1f3f9;
}

/* .g2-html-annotation {
  font-size: 13px !important;
} */

.ant-table-cell {
  max-width: 33% !important;
}

.ant-table-pagination.ant-pagination {
  margin: 6px 0;
}

.ant-tabs-tab-btn:hover,
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:active {
  color: $teal;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $teal;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: $teal !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #f5f5f5 !important;
  border: 1px solid grey !important;
}

.ant-btn-primary {
  background: $teal;
  border: none;
}

.ant-btn-primary:hover {
  background-color: $tealHover;
  border: none;
}

html {
  --antd-wave-shadow-color: $teal !important;
}

.btn,
.btn::after,
.btn:focus,
.btn .antd-btn .antd-btn-default {
  background-color: white;
  color: $teal;
  border: 1px solid $teal;
  border-radius: 7px;
  width: 120px;
  font-weight: 500;

  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: rgb(45, 206, 152);
  color: white;
  border: 1px solid transparent;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  background-color: transparent !important;
}

.uploadOfficePlanButton button {
  background-color: white;
  color: $teal;
  border: 1px solid $teal;
  transition: all 0.2s ease-in-out;
  border-radius: 7px;
  width: 120px;
  font-weight: 500;
}

.userSearchSelect {
  position: relative;
  margin-bottom: 2rem;
  left: 0.7rem;
}

.userSearchSelect .ant-select-selector {
  border-radius: 20px !important;
}

.userSearchSelect .ant-select-arrow {
  right: 0 !important;
  left: 11px;
}

.userSearchSelect
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.userSearchSelect
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  margin-left: 20px !important;
}
.greenBtn {
  /* margin-right: 10px; */
  background: $teal;
  color: white;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: $tealHover;
  }
}

.redButton {
  color: white;
  background-color: $red;
  border-radius: 5px;
  border: none;

  transition: all 0.3s ease-in-out;
}

.redButton:hover {
  background-color: $redHover;
  color: white;
  border: none;
}

p {
  font-size: 1.1rem;
}

.ant-layout-sider-trigger {
  position: relative;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="light"] .site-layout .site-layout-background {
  background: #141414;
}

.ant-menu > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
}

.iconLogo {
  font-size: 100%;
  margin-top: 20px;
  margin-right: 7px;
}

.sideBarLogo {
  background: url("./assets/Logo\ MY\ DESK.png") no-repeat;
  background-size: 100%;
}

.sideMenu {
  overflow-x: hidden;
}

.ant-list-empty-text {
  position: relative !important;
  height: 100vh !important;
  top: 120px !important;
}

.ant-pagination-item-link {
  border-radius: 50% !important;
}

.ant-pagination-item-link:hover {
  border-color: $teal !important;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #f7fafc;
}

.anticon-logout {
  height: 15px !important;
}

.ant-list-empty-text {
  height: 40vh !important;
  top: 60px !important;
}

.ant-drawer-content-wrapper {
  width: 250px !important;
}

@media screen and (max-width: 820px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 6px;
    overflow-wrap: break-word;
  }

  .userSearchSelect {
    margin-bottom: 0.7rem;
    left: 5px;
  }
}

@media (max-width: 580px) {
  .ant-picker-panel-container .ant-picker-panels {
    display: block;
    width: min-content;
  }
}

@media screen and (max-width: 430px) {
  .userSearchSelect {
    margin-bottom: 0.7rem;
    left: 0px;
  }
}
