@import "../../sass/abstracts/variables";

.labels {
  font-weight: bold;
  font-size: 1em;
}

.officeTitles {
  font-size: 1.35em;
}

.inputvalue {
  text-align: center;
}
.inlineBlockDiv {
  display: inline-block;
}

.imageRow {
  display: flex;
  justify-self: center;
}

.secondSpan {
  display: flex;
  justify-content: start;
}

.secondSpanconf {
  display: flex;
  justify-content: start;
  padding-right: 13%;
}

.deleteiconDesks {
  cursor: pointer;
  padding-left: 27%;
}

.deleteiconconf {
  cursor: pointer;
  padding-left: 27%;
}

.deleteButton {
  display: flex;
  float: right;
}

.firstSpan {
  display: flex;
  justify-content: end;
}

.divSpan {
  display: flex;
  justify-content: space-between;
}

.ant-col {
  border: 0 !important;
}

.officeName {
  position: relative;
  left: 9px;
}

.checkbox {
  background-color: white;
  color: black;
  padding-left: 3%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerSize {
  width: 90%;
}

.firstRow {
  margin-bottom: 20px;
}

.h2 {
  font-size: 1.125rem;
}

.form {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.input {
  width: 250px;
  border-radius: 7px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 400px;
  background-color: white;
}

.btn {
  width: 150px !important;
}

.imgContainer {
  background-color: white;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.officeImagePlan {
  width: 500px;
  height: 400px;
  object-fit: contain;
}

.btn,
.btn::after,
.btn:focus,
.btn .antd-btn .antd-btn-default {
  width: 150px !important;
}

.tableRow {
  justify-content: center;
}

@media screen and (max-width: 820px) {
  .tableCol {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .imgContainer {
    background-color: white;
    height: 100%;
  }
  .officeImagePlan {
    width: 100% !important;
    height: 100% !important;
  }
}
