//COLORS FOR BUTTONS

$teal: #5cb1b8;
$tealHover: #6dcfd8;

$red: #d11a2a;
$redHover: #e74552;

$orange: rgb(255, 166, 0);
$orangeHover: rgb(255, 196, 124);

$boxShadow: 0px 3px 17px rgba(18, 18, 18, 0.2);
