.sideBar {
  overflow: none;
  height: 100.7vh;
  position: fixed !important;
  left: 0;
}

.sideBarLogo {
  width: 130px !important;
  top: 7px;
  left: 22px;
  min-height: 70px;
}

@media screen and (max-width: 820px) {
  .sideBarLogo {
    width: 75px !important;
    top: 10px;
    left: 2px;
  }
}
