.headerHome {
  display: flex;
  width: 100%;
  float: right;
  justify-content: space-between;
  align-items: center;
  background-color: transparent !important;
  margin-top: 1em;
  margin-bottom: 1.2rem;
}

.header {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: transparent !important;
  margin-top: 1em;
  margin-bottom: 1.2rem;

  .container {
    position: relative;
    left: 40px;
  }
}

.sideBarLogo {
  background: url("../../assets/Logo\ MY\ DESK.png") no-repeat;
  width: 145px !important;
  min-height: 70px;
  position: relative;
  top: 5px;
  left: -5% !important;
  background-size: 100%;
}

.link {
  margin-right: 10px;
  color: white;
  font-size: 15px;
  font-weight: 500px;
  transition: all 0.2s ease-in-out;
  z-index: 3;
  &:hover {
    cursor: pointer;
    color: rgb(252, 252, 252);
    text-shadow: 0 0 3px white;
  }
}

.accountFunc {
  font-size: larger;
}

.container {
  display: flex;
  align-items: center;
  width: 88%;
  justify-content: flex-end;
  position: relative;
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}

.items {
  display: flex;
  flex-direction: row-reverse;
}

.logoutBtn {
  position: relative;
  top: 23px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: drop-shadow(0 0 3px white) !important;
  }
}

.logoMenu {
  .menuIcon {
    position: relative;
    top: 5px;
    left: -35px;
    color: black;
    font-size: 40px;
    margin-right: 5px;
    z-index: 1000;
  }

  .menuIconHide {
    z-index: -1;
  }
}

.noDisplay {
  display: none;
}

.drawerContentOpen {
  width: 225px !important;
  z-index: 1000 !important;
}

.drawerContentClosed {
  z-index: -1 !important;
}

@media screen and (max-width: 820px) {
  .header {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    justify-content: space-between;
  }

  .container {
    width: max-content;
  }

  .sideBarLogo {
    width: 110px !important;
    left: 0% !important;
  }

  .logoMenu {
    .menuIcon {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 580px) {
  .container {
    left: 40px;
  }
  .avatar {
    width: 40px !important;
    height: 40px !important;
  }
  .link {
    font-size: 13px;
  }
}
