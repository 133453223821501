@import "../../sass/abstracts/variables";

.bg {
  display: flex;
  background: url("../../assets/Path\ 467.svg");
  background-position: 0 -17rem;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}

.logo {
  background: url("../../assets/Logo\ MY\ DESK.svg") no-repeat;
  width: 142px;
  height: 54px;
  margin: 5px;
}

.login {
  width: 476px;
  height: 470px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f7fafc;
  flex-direction: column;
  box-shadow: 0px 3px 17px #2c28281c;
  border-radius: 10px;
  opacity: 1;

  p {
    font-size: 1rem;
    margin-top: -40px;
    margin-bottom: -22px;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title > p {
  color: #172b4d;
  text-align: center;
  font: normal normal normal 15px/20px;
  letter-spacing: 0px;
  color: #172b4d;
  opacity: 1;
  font-size: 1rem;
  margin: 0px;
  padding: 10px;
}

.btn {
  height: 60px;
  position: relative;
  top: -1px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 90px;
  margin-top: -30px;
}

.input {
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 2px;
}

.buttons {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.014);
  margin-top: 5px;
}

.buttons.tealBtn:hover:enabled {
  background-color: $tealHover;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.014);
}

.tealBtn {
  background-color: $teal;
  color: white;
}

.register {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  box-shadow: none;
  width: fit-content;

  & :hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
  }
}

.rememberwrap {
  display: flex;
  justify-content: space-between;
}

.alert {
  height: 45px;
  font-size: 13px;
}

.loginDivider {
  color: darkgray;
  margin-top: -30px !important;
  margin-bottom: -25px !important;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .login {
    width: 330px;
  }

  .form {
    padding: 0 50px;
  }

  .alert {
    height: 55px;
    font-size: 12px;
  }
}

@media screen and (max-width: 280px) {
  .login {
    width: 250px;
    .loginDivider {
      font-size: 0.8rem;
      margin-top: -40px;
      margin-bottom: -22px;
    }
  }

  .form {
    padding: 0 20px;
  }
}
