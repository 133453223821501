.loading {
  /*position middle*/
  display: flex;
  width: 100%;
  height: 15vh;
  justify-content: center;
  align-items: center;
}

.dots {
  width: 56px;
  height: 31.4px;
  background: radial-gradient(farthest-side, #0bd6ae 90%, #0000) 50% 0,
    radial-gradient(farthest-side, #0bd6ae 90%, #0000) 100% 0;
  background-size: 13.4px 13.4px;
  background-repeat: no-repeat;
  position: relative;
  animation: dots-pr30iymd 1.5s linear infinite;
}

.dots:before {
  content: "";
  position: absolute;
  width: 13.4px;
  height: 13.4px;
  border-radius: 50%;
  background: #0bd6ae;
  left: 0;
  top: 0;
  animation: dots-q6bpf4md 1.5s linear infinite,
    dots-b39z9imd 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes dots-pr30iymd {
  0%,
  31% {
    background-position: 50% 0, 100% 0;
  }

  33% {
    background-position: 50% 100%, 100% 0;
  }

  43%,
  64% {
    background-position: 50% 0, 100% 0;
  }

  66% {
    background-position: 50% 0, 100% 100%;
  }

  79% {
    background-position: 50% 0, 100% 0;
  }

  100% {
    transform: translateX(calc(-100% / 3));
  }
}

@keyframes dots-q6bpf4md {
  100% {
    left: calc(100% + 7.8px);
  }
}

@keyframes dots-b39z9imd {
  100% {
    top: -0.17px;
  }
}
